import React, { createContext } from "react";

export const AppContext = createContext();

export const AppProvider = (props) => {
    const gatewayHealthcheck = () => {
        return "Hi there!";
    }

    return (
        <AppContext.Provider
            value={{
                gatewayHealthcheck
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
}