import React from 'react';
import '../styles/FooterStyle.css';

export default function Footer() {

    return (
        <footer class="footer">
            <div class="footer-content">
                <div class="footer-section about">
                    <h2>About Us</h2>
                    <p>We are a company dedicated to providing high-quality products and services.</p>
                </div>
                <div class="footer-section links">
                    <h2>Links</h2>
                    <ul>
                        <li><a href="#">Contact</a></li>
                        <li><a href="#">Services</a></li>
                        <li><a href="#">Careers</a></li>
                        <li><a href="#">News</a></li>
                        <li><a href="#">About</a></li>
                    </ul>
                </div>
                <div class="footer-section contact">
                    <h2>Contact Us</h2>
                    <p>Email: info@brainarq.com</p>
                    <p>Phone: +1 (312) 9347463</p>
                    <p>Address: 1611 N Windsor Dr, Apt 305, 60004, Arlington Heights, Illinois</p>
                </div>
            </div>
            <div class="footer-bottom">
                &copy; 2024 Brainarq LLC. All rights reserved.
            </div>
        </footer>
    );
}