import React from 'react';
import "./styles/default.css";

import { AppProvider } from "./context/AppContext";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";


export default function App() {
    return (
        <AppProvider>
            <Navbar />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Footer />/
        </AppProvider>
    );
}