import React, { useState } from 'react';
import useWindowSize from './non-visual/WindowResize';
import '../styles/NavStyle.css';

export default function Navbar() {
    const [isActive, setIsActive] = useState(false);
    const [expandedSubmenus, setExpandedSubmenus] = useState({});
    const { width } = useWindowSize();
    const isMobileView = width <= 768;

    const toggleMenu = () => {
        setIsActive(!isActive);
        document.body.classList.toggle('no-scroll', !isActive);
    };

    const toggleSubmenu = (index) => {
        setExpandedSubmenus(prevState => ({
            ...prevState,
            [index]: !prevState[index]
        }));
    };

    const handleMenuClick = () => {
        setExpandedSubmenus({});
        toggleMenu();
    };

    return (
        <header className="header">
            <div className="logo">Brainarq</div>
            <nav className={`nav${isActive ? ' active' : ''}`}>
                <ul className={`${isMobileView ? 'mobile-ul' : 'desktop-ul'}`}>
                    <li><a href="#home" onClick={handleMenuClick}>Home</a></li>
                    <li>
                        <a href="#about" onClick={() => toggleSubmenu(1)}>About</a>
                        {expandedSubmenus[1] && (
                            <div className="submenu expanded">
                                <li className="submenu-item"><a href="#company" onClick={handleMenuClick}>Company</a></li>
                                <li className="submenu-item"><a href="#clients" onClick={handleMenuClick}>Clients</a></li>
                                <li className="submenu-item"><a href="#ceo" onClick={handleMenuClick}>CEO</a></li>
                            </div>
                        )}
                        <div className="desktop-submenu">
                            <li className="submenu-item"><a href="#company">Company</a></li>
                            <li className="submenu-item"><a href="#clients">Clients</a></li>
                            <li className="submenu-item"><a href="#ceo">CEO</a></li>
                        </div>
                    </li>
                    <li>
                        <a href="#services" onClick={() => toggleSubmenu(2)}>Services</a>
                        {expandedSubmenus[2] && (
                            <div className="submenu expanded">
                                <li className="submenu-item"><a href="#web-development" onClick={handleMenuClick}>Web Development</a></li>
                                <li className="submenu-item"><a href="#graphic-design" onClick={handleMenuClick}>Graphic Design</a></li>
                                <li className="submenu-item"><a href="#seo" onClick={handleMenuClick}>SEO</a></li>
                            </div>
                        )}
                        <div className="desktop-submenu">
                            <li className="submenu-item"><a href="#web-development">Web Development</a></li>
                            <li className="submenu-item"><a href="#graphic-design">Graphic Design</a></li>
                            <li className="submenu-item"><a href="#seo">SEO</a></li>
                        </div>
                    </li>
                    <li>
                        <a href="#careers" onClick={() => toggleSubmenu(3)}>Careers</a>
                        {expandedSubmenus[3] && (
                            <div className="submenu expanded">
                                <li className="submenu-item"><a href="#internships" onClick={handleMenuClick}>Internships</a></li>
                                <li className="submenu-item"><a href="#full-time" onClick={handleMenuClick}>Full-Time</a></li>
                                <li className="submenu-item"><a href="#part-time" onClick={handleMenuClick}>Part-Time</a></li>
                                <li className="submenu-item"><a href="#internships" onClick={handleMenuClick}>Internships</a></li>
                                <li className="submenu-item"><a href="#full-time" onClick={handleMenuClick}>Full-Time</a></li>
                                <li className="submenu-item"><a href="#part-time" onClick={handleMenuClick}>Part-Time</a></li>
                                <li className="submenu-item"><a href="#internships" onClick={handleMenuClick}>Internships</a></li>
                                <li className="submenu-item"><a href="#full-time" onClick={handleMenuClick}>Full-Time</a></li>
                                <li className="submenu-item"><a href="#part-time" onClick={handleMenuClick}>Part-Time</a></li>
                            </div>
                        )}
                        <div className="desktop-submenu">
                            <li className="submenu-item"><a href="#internships">Internships</a></li>
                            <li className="submenu-item"><a href="#full-time">Full-Time</a></li>
                            <li className="submenu-item"><a href="#part-time">Part-Time</a></li>
                            <li className="submenu-item"><a href="#internships">Internships</a></li>
                            <li className="submenu-item"><a href="#full-time">Full-Time</a></li>
                            <li className="submenu-item"><a href="#part-time">Part-Time</a></li>
                            <li className="submenu-item"><a href="#internships">Internships</a></li>
                            <li className="submenu-item"><a href="#full-time">Full-Time</a></li>
                            <li className="submenu-item"><a href="#part-time">Part-Time</a></li>
                        </div>
                    </li>
                    <li><a href="#contact" onClick={handleMenuClick}>Contact</a></li>
                </ul>
            </nav>
            <div className={`menu-icon${isActive ? ' open' : ''}`} onClick={toggleMenu} />
        </header>
    );
}
